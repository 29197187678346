'use client';
import {FooterCTA} from '../AuthFooter';
import AuthHeader, {AuthHeaderProps} from '../AuthHeader';
import AuthLayout from '../AuthLayout';
import NewAuth from '../NewAuth';
import LoginForm from './LoginForm';
import {PROTOCOL_HOSTNAME} from '@/constants/global';
import {useAuth} from '@/context/AuthProvider';
import {useAuthPath, useIsProductAi} from '@/hooks';
import {RegistrationProcessEnum} from '@/models/addEmail';

export default function Login({overline, headline, body}: AuthHeaderProps) {
  const {login, error, isSuccess} = useAuth();
  const {signupPath} = useAuthPath();
  const isAi = useIsProductAi();

  const signUpUrl = isAi
    ? `${PROTOCOL_HOSTNAME}/signup?process=${RegistrationProcessEnum.RegistrationAi}&redirect=${PROTOCOL_HOSTNAME}/ai&utm_source=PrescriberPointAI&utm_medium=ai_tool&utm_campaign=prescriber-ai`
    : signupPath;

  const footerCTAs: FooterCTA[] = [
    {
      label: '',
      href: '/forgot-password',
      button: 'Forgot Password',
    },
    {
      label: "Don't have an account?",
      href: signUpUrl,
      button: 'Sign up',
    },
  ];

  return isAi ? (
    <NewAuth />
  ) : (
    <AuthLayout>
      <AuthHeader overline={overline} headline={headline} body={body} />
      <LoginForm
        onSubmit={login}
        // @ts-ignore
        errorMessage={error?.message as string}
        isLoading={isSuccess}
        footerCtas={footerCTAs}
      />
    </AuthLayout>
  );
}
